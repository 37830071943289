body {
  background: #000;
}

.section {
  margin: 20px 0;
}

.section h3 {
  margin-bottom: 20px;
  font-size: 1.6em;
  color: white;
}

.section a {
  text-decoration: none;
  color: #0f0;
}

.link-item {
  display: inline-block;
  margin: 20px;
  text-align: center;
  vertical-align: top;
}

.link-item a {
  text-decoration: none;
  color: #0f0;
  display: block;
  width: 160px;  /* Adjust width if needed */
  margin: 0 10px;  /* Margin between icons */
}

.link-item img {
  width: 140px;  /* Fixed width */
  height: 140px;  /* Fixed height */
  border-radius: 15px;
  background: linear-gradient(to bottom, #0f0, black);
  color: #0f0;
  padding: 10px;
  box-sizing: border-box;  /* Ensure padding is included in width/height */
}

.link-item img:hover {
  background: linear-gradient(to bottom, #ff0, #000); /* Shift background from green to yellow */
}

.link-item span {
  display: block;
  margin-top: 10px;
  text-decoration: none;
  color: #0f0;
}

.buy-widget {
  width: 400px;
  margin: auto;
}

.buy-desc {
  width: 80%;
  margin: auto;
}

.buy-desc p {
  margin-top: 10px;
  text-decoration: none;
  color: #0f0;
}

@media (max-width: 768px) {
  .link-item {
    width: 45%;
  }
}

@media (max-width: 480px) {
  .link-item {
    width: 100%;
  }
}

/* Modal overlay and content styles */
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  border: 1px solid #0f0;
  color: #0f0;
  border-radius: 10px;
  padding: 20px;
  max-width: 600px;
  width: 90%;
  margin: auto;
  max-height: 80%;
  overflow-y: auto;
  background: #000
}

.ReactModal__Content h2 {
  margin-top: 0;
}

.ReactModal__Content a {
  text-decoration: none;
  color: #fff;
}

.ReactModal__Content button {
  background: #0f0;
  border: none;
  color: #000;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.ReactModal__Content button:hover {
  background: #0a0;
}
