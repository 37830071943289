.copyable-address {
  display: inline-flex;
  align-items: center;
}

.copy-icon {
  margin-left: 10px;
  cursor: pointer;
  color: white;
}

.copy-icon:hover {
  color: #ff0; /* Change color on hover */
}

/* Tooltip styling */
#copyTooltip {
  background: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  padding: 5px 10px;
  text-align: center;
}
