.cta-section {
  width: 80vw;      /* 80% of the viewport width */
  margin: auto;     /* Centers the section in the viewport horizontally and vertically */
  display: block;    /* Use flexbox for easy alignment */
  justify-content: center; /* Horizontally center content */
  align-items: center;     /* Vertically center content */
  text-align: left;      /* Ensure text within the content is centered */
  font-size: 1.3em;        /* 30% larger than the base font size */
  color: #0f0;             /* Matrix-style green */
  overflow: none;          /* Adds scroll if content is larger than the box */
}

.cta-section p {
  display: block;
  text-align: left;
}

.cta-section h2 {
  text-align: center;
  color: white;
}
